/* ------------------- */
/* Variables           */
/* ------------------- */
:root{
    --main-color:#000000;
    /* fonts */
    --ff-trade-gothic:trade-gothic-next-compressed, sans-serif;
    /* font weights */
    --fw-nav:400;
    /* hamburger menu vaiables */
    --bar-width:30px;
    --bar-height:1.17px;
    --bar-gap:11.24px;
    --foreground:#606060;
    --background:#FFFFFF;
    --animation-timing:350ms ease-in-out;
    --hamburger-height: calc(var(--bar-height) * 3  + var(--bar-gap) * 2);
    /* section background color */
    --section-bg: #F0F5F5;
    --clip: circle(63.7332% at 47.8454% 47.8454%);
}
/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
/* ------------------- */
/* CSS - Reset               */
/* ------------------- */
/* Box sizing */
*,
*::before,
*::after {
    box-sizing: border-box; 
}
/* Reset margins */
body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
picture {
    margin: 0;
}

@media (max-width:320px){
    body{
       overflow: scroll;
       -webkit-text-size-adjust: 100%;
    }
}

/* set up the body */
body {
    line-height: 1.5;
    min-height: 100dvh;
    max-width: 100vw;
    overflow-x: hidden !important;
    position: relative;
    color: var(--main-color);
    margin: 0;
    padding: 0; 
    display: flex;
    flex-direction: column;
    gap: 80px;
}
@media (max-width:600px){
    body{
        gap: 40px;
    }
}
/* make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}
/* make form elements easier to work with */
input,
button,
textarea,
select {
    font: inherit;
}
ul[role='list'],
ol[role='list'] {
  list-style: none;
}
/* remove animations for people who've turned them off */
@media (prefers-reduced-motion: reduce) {  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* override ios apple mail meddling */
*[x-apple-data-detectors],  /* iOS */
    .x-gmail-data-detectors,    /* Gmail */
    .x-gmail-data-detectors *,
    .aBn {
        border-bottom: 0 !important;
        cursor: default !important;
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
    }
/* ------------------- */
/*   UTILITY CLASSES   */
/* ------------------- */
.main-grid{
    display: grid;
    grid-template-columns: 1fr 70% 1fr;
    justify-items: center;
}
@media (max-width:600px){
    .main-grid{
        display: grid;
        grid-template-columns: 1fr 85% 1fr;
    } 
}
.sr-only {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
}
.cursor-pointer:hover{
    cursor: pointer;
}
html, body {
    overflow-x:hidden 
  } 
/* ------------------- */
/*       decor    */
/* ------------------- */
/* bg animation styles */
.full {
    width: 100%;
    height: 100vh;
    overflow:hidden;
  }
  .dot {
    position: absolute;
    width:142vmax;
    height:142vmax;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%) scale(0);
    border-radius: 50%;
    background-color:#F0F5F5;
  }
/* loop in company name section */
.company-name{
    position: relative;
}
.loop-one{
    position: absolute;
    bottom: -700px;
    right: -30%;
    z-index: 6;
}
@media (min-width: 820px) and (max-width: 1024px) {
    .loop-one {
        bottom: -1250%;
        right: -45%;
        z-index: 6;
        transform: scale(2.5);
    }
}
@media (max-width:820px){
    .loop-one{
        
        bottom: -800%;
        right: -45%;
        z-index: 6;
        transform: scale(2.5);
    }
}
.about-bottom{
    z-index: 8;
    overflow: visible;
}
.about-center{
    z-index: 7;
}
.about-container{
    z-index: 1;
}
/* loop at the end of the about section  */
#about{
    position: relative;
    /* overflow: hidden; */
}
.loop-two{
    position: absolute;
    bottom: -400px;
    right: 40%;
    z-index: 6;
}
@media (max-width:700px){
    .loop-two{
        position: absolute;
        bottom: -20px;
        right: 70%;
        transform: scale(2)
    }
}
@media (min-width: 700px) and (max-width: 1024px) {
    .loop-two{
        position: absolute;
        bottom: -190px;
        right: 30%;
        z-index: 6;
    }
}
/* loop in the service section  */
.service-main{
    position: relative;
}
.carousel{
    position: relative;
}
/* loop in the company section */
.company-main{
   position: relative;
   transform: translateY(-300px);
}
.loop-five {
    width: 321.2738px;
    position: absolute;
    bottom: -40%;
   right: 50px;
}
@media (max-width:820px){
    .loop-five {
        width: 321.2738px;
        position: absolute;
        bottom: -40%;
       right: -100px;
    }
}
/* loop in topics */
.topics-main{
    position: relative;
    z-index: -1;
 }
 .loop-six {
     width: 321.2738px;
     position: absolute;
     top: -350px;
    left: -130px;
 }
 @media (min-width:820px){
     .loop-six {
        top:-350px;
        left: 20%;
        transform: scale(1.5);
     }
 }
/* additional resets etc */
body {
    margin: 0;
    overflow-x: hidden;
    background-color:white;
    color:white;
    font-family:sans-serif;
  }
  h2 {
    font-size:clamp(24px, 5vw, 50px);
  }
  p {
    font-size:clamp(18px, 3vw, 30px);
    line-height:clamp(1.5em, 2vw, 3em);
  }
/* ------------------- */
/*       Nav           */
/* ------------------- */
body{
    max-width: 100vw;
}
#nav{
    display: flex;
    background-color: white !important;
    justify-content: center;
    align-items: center;
    margin-left: 61.93px;
    margin-top: 34.38px;
    position: relative;
}
#navigation{
    box-shadow: none;
}
.primary-nav-svg{
    width: 8.8791px;
    height: 9.0395px; 
}
.logo{
    position: absolute;
    top:4px;
    left: 0;
    width: 196.19px;
    height: 57.6px;
}
.nav-links{
    text-decoration: none;
    color: var(--main-color);
    font-style: normal;
    font-family: var(--ff-trade-gothic);
    font-weight: var(--fw-nav);
    font-size: 20px;
    letter-spacing: 0.8px;
}
.primary-nav{
    display: flex;
    gap: 2.6875rem;
    height: 100%;
    padding: 0;
    background-color: white !important;
    box-shadow: none !important;
}
.primary-nav-item{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5938rem;
    height: 100%;
}
.primary-nav-item:hover{
    cursor: pointer;
}
.hamburger-menu{
    display: none;
}
.nav-link-label{
    width: 100%;
    display: flex;
}
/* tablet and mobile  */
@media (max-width:950px){
    .primary-nav-wrapper{
        background-color: white;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        transition: transform var(--animation-timing);
        transform: translateX(100%);
        z-index: 9998;
    }
    .primary-nav{
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        padding-left: 1rem;
    }
    .nav-links{
        font-size: 2rem;
    }
    .primary-nav-svg{
        width: 16px;
        height: 18px;
    }
    .logo{
        position: static;
    }
    #nav{
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 9.57px;
    }
    .hamburger-menu{
        --x-width:calc(var(--hamburger-height) * 1.41421356237); /* the length of the bar when its an X (hamburger menu height  * squareroot of 2) */
        display: flex;
        flex-direction: column;
        gap: var(--bar-gap);
        width: max-content;
        z-index: 9999;
        cursor: pointer;
    }
    .hamburger-menu::before,
    .hamburger-menu::after,
    .hamburger-menu input{
        content: "";
        width: var(--bar-width);
        height: var(--bar-height);
        background-color: var(--foreground);
        border-radius: 9999px;
        transform-origin: left center;
        transition: opacity var(--animation-timing), width var(--animation-timing),translate var(--animation-timing),rotate var(--animation-timing);
    }
    .hamburger-menu input{
        appearance: none;
        padding: 0;
        margin: 0;
        outline: none;
        pointer-events: none;
    }
    .hamburger-menu:has(input:checked)::before{
        rotate: 45deg;
        width: var(--x-width);
        translate: 0 calc(var(--bar-height) / -2);
    }
    .hamburger-menu:has(input:checked)::after{
        rotate: -45deg;
        width: var(--x-width);
        translate: 0 calc(var(--bar-height)/2);
        
    }
    .hamburger-menu input:checked{
        opacity: 0;
        width: 0;
    }
    .hamburger-menu:has(input:checked) + .primary-nav-wrapper{
        transform: translateX(0);
    }
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        opacity: 1;
        position: static;
    }

/* Style the labels for the navigation links */
.nav-link-label {
    cursor: pointer;
  }
  
  /* Style the labels when the checkbox is checked */
  .hamburger-checkbox:checked + .primary-nav-wrapper .nav-link-label {
    display: block;
  }
  
  /* Style the labels when the checkbox is unchecked */
  .hamburger-checkbox + .primary-nav-wrapper .nav-link-label {
    display: none;
  }
}

/* mobile */
@media (max-width:600px){
    .logo{
        width: 160px;
        height: 46.97px;
    }
    #nav{
        gap: 120px;
        margin-left: 20px;
        margin-top: 9.57px;
    }
}

/* ------------------- */
/*       hero          */
/* ------------------- */

/* hero top */
.hero-top-container{
display: flex;
justify-content: space-between;
align-items: center;
margin-left: 240px;
margin-right: 85px;
margin-bottom: 40px;
position: relative;
}

.hero-top-img{
    text-wrap: nowrap;
}
/* scroll to contact btn */
.scroll-btn{
    position: relative;
    width: 122.02px;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    position: absolute;
    top:-30px;
    right:0;
}
.scroll-btn-arrow{
    width: 37px;
    height: 37px;
    z-index: 2;
}

.rotate-text{
    position: absolute;
    inset: 0;
    animation-name: rotate-circle;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes rotate-circle {
    to {
      transform: rotate(1turn);
    }
  }

  /* hero top image styles */
  .hero-title{
    width: 267px;
    height: 43px;
    color: black;
    font-size: 35.17px;
    margin-right: 40px;
    z-index: -1;
    font-family: var(--ff-trade-gothic);
    letter-spacing: 1.41px;
  }
  @media (min-width:950px) {
    .hero-title{
        width: 684px;
        height: 110px;
    }
  }
  /* mobile */
  @media (max-width:950px){
      .hero-top-container{
        margin-top: 0px;
        margin-left: 19.97px;
        margin-right: 18.39px;
        margin-bottom: 12px;
        }
        .scroll-btn{
            width: 73.21px;
        }
        .scroll-btn-arrow{
            width: 22px;
            height: 22.35px;
        }
  }
  @media (max-width:600px){
    .hero-top-img::after{
        font-size: 25px;
        height: 43px;
      }
    .scroll-btn{
        width: 60px;
    }
    .scroll-btn-arrow{
        width: 16px;
        height: 17px;
    }
  }
/* hero Key visual */
.carousel-container{
  overflow: hidden;
  height: 180px;
}
.kv {
    width: 320px;
    height: 180px;
}
.slides{
   width: 100%;
   display: flex;
   gap: 5px;
}
.slide{
    flex-grow: 1;
   flex-shrink: 0;
   transform: translateX(10vw);
}
@media (min-width: 720px) and (max-width: 1200px) {
    .carousel-container {
        height: 339px; 
    }
    .kv {
        width: 600px; 
        height:339px; 
    }
    .slides {
        gap: 5px; 
    }
    .slide{
        flex-grow: 1;
       flex-shrink: 0;
       transform: translateX(13vw);
    }
}

@media (min-width:1200px){
    .carousel-container {
        height:568px; 
    }
    .kv {
        width: 1010px; 
        height:568px;
    }
    .slides {
        gap: 5px; 
    }
    .slide{
        flex-grow: 1;
       flex-shrink: 0;
       transform: translateX(400px);
    }
}
/* company name */
.company-name{
    display: grid;
    grid-template-columns: 1fr 50% 1fr;
    justify-items: center;
    position: relative;
    width: 100vw;
    height: 48px;
}
#company-name{
    font-size: 38.6px; /* 38.6 px */
    letter-spacing: 1px;
    background-color: transparent;
    z-index: 7;
    -webkit-text-size-adjust: 100%;
    margin-top: 10px;
}
.company-name h3{
    grid-column: 2/3;
    justify-self: end;
    font-family: var(--ff-trade-gothic);
    z-index: 3;
    color:black;
}
.char{
    opacity: 0.2;
    /* font-size: 24px; */
    font-size: 38.6px;
    margin-right: 0.48px;;
}
@media (max-width:600px){
    .company-name{
        display: block;
        height: 30px;
    }
    #company-name{
        /* position: absolute;
        right: 0; */
        font-size: 1.5rem; /* 24 px */
        letter-spacing: -0.77px;
        font-kerning: none;
        
    }
}
@media (max-width:700px){
    .char{
        font-size: 24px;
    }
}
@media (max-width:1200px){
    .company-name{
        display: block;
    }
    #company-name{
        position: absolute;
        right: 0;
        margin-right: 10%;
    }
}
/* ------------------- */
/*       about         */
/* ------------------- */

#about{
    height: 100%;
    color: black;
   
}

.bg-img{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}
.about-container{
   overflow-y: visible;
   padding-bottom: 50px;
   padding-top: 50px;
}
/* about top styles */
.about-top{
    margin-top: 3rem;
    height: 347px;
    grid-column: 2/3;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
@media (max-width:600px){
    .about-top{
    flex-direction: column;
    } 
}
/* top right */
.about-top_left{
    display: grid;
    place-items: center;
    letter-spacing: 0px;
    z-index: 3;
}
.about-top-title_eng{
    color: #FFFFFF;
    font-size: 130px;
    white-space: nowrap; 
    z-index: 2;
    font-family: a-otf-ud-shin-go-con80-pr6n, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.about-top-title_jp{
    z-index: 3;
    position: absolute;
    font-family: a-otf-ud-shin-go-con80-pr6n, sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0px;
    font-size: 30px;
}
.about-top_right p{
    font-size: 22px;
    font-family: a-otf-ud-shin-go-con80-pr6n, sans-serif;
    font-weight: 300;
    font-style: normal;
    z-index: 3;
}
.about-top_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 370px;
}
@media (max-width:800px){
    .about-top-title_eng{
        color: #FFFFFF;
        font-size: 80px; 
    }
    .about-top-title_jp{
        font-size: 22px;
    }
    .about-top_right p{
        font-size: 16px; 
    }
    .about-top_right {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 270px;
        text-align: center;
        gap: 40px;
    }
}

/* about center */
.about-center{
    display: grid;
    place-items: center;
    grid-column: 1/5;
    margin-top: 0;
    background-color: var(--section-bg);
}
.about-container{
    transform: translateY(-110px);
}
@media (min-width:394px)and (max-width:760px){
    .about-container {
        transform: translateY(-140px);
    }
}
@media (max-width:393px){
    .about-container {
        transform: translateY(-100px);
    }
}
.about-us-list{
    width: 660px;
    aspect-ratio: 1;
    border-radius: 50%;
    position: relative;
}
.about-us-list-item{
    width: 200px;
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    left: 50%;
    top:50%;
    margin: -100px;
    z-index: 3;
}
.item-1{
    transform: rotate(22.5deg) translate(330px) rotate(-22.5deg);
}
.item-2 {
    transform: rotate( 67.5deg) translateX(330px) rotate(-67.5deg);
}

.item-3 {
    transform: rotate(112.5deg) translateX(330px) rotate(-112.5deg);
}

.item-4 {
    transform: rotate(157.5deg) translateX(330px) rotate(-157.5deg);
}

.item-5 {
    transform: rotate(202.5deg) translateX(330px) rotate(-202.5deg);
}

.item-6 {
    transform: rotate(247.5deg) translateX(330px) rotate(-247.5deg);
}

.item-7 {
    transform: rotate(292.5deg) translateX(330px) rotate(-292.5deg);
}

.item-8 {
    transform: rotate(337.5deg) translateX(330px) rotate(-337.5deg);
}
.center{
    border: none;
    width: 660px;
    aspect-ratio: 1;
    margin: -330px;
    letter-spacing: 0px;
    font-family: a-otf-ud-shin-go-con80-pr6n, sans-serif;
    font-weight: 300;
    font-style: normal;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
 }
 .center p{
    font-size: 46px;
    width: 368px;
    height: 277px;
 }
 .sub-item{
    background-image: url(./assets/images/transparentBkg/transparentBkg2x.png);
    background-size: cover;
 }
 .sub-item p{
    font-size: 24px;
    font-family: a-otf-ud-shin-go-con80-pr6n, sans-serif;
    font-weight: 300;
    font-style: normal;
 }
 @media (max-width:800px){
    .about-us-list{
        width: 272.5px;
    }
    .about-us-list-item{
        width: 92px;
        margin: -46px;
        z-index: 3;
    }
    .sub-item{
        font-size: 14px; 
        width: 92px;
     }
     .center{
        border: none;
        font-size: 46px;
        width: 272.5px;
        margin: -136.255px;
     }
     .center p{
        width: 152px;
        height: 114px;
        font-size: 19px;
     }
     .item-1{
        transform: rotate(22.5deg) translate(136.255px) rotate(-22.5deg);
    }
    .item-2 {
        transform: rotate( 67.5deg) translate(136.255px) rotate(-67.5deg);
    }
    
    .item-3 {
        transform: rotate(112.5deg) translate(136.255px) rotate(-112.5deg);
    }
    
    .item-4 {
        transform: rotate(157.5deg) translate(136.255px) rotate(-157.5deg);
    
    }
    
    .item-5 {
        transform: rotate(202.5deg) translate(136.255px) rotate(-202.5deg);
    }
    
    .item-6 {
        transform: rotate(247.5deg) translate(136.255px)rotate(-247.5deg);
    }
    
    .item-7 {
        transform: rotate(292.5deg) translate(136.255px) rotate(-292.5deg);
    }
    
    .item-8 {
        transform: rotate(337.5deg) translate(136.255px) rotate(-337.5deg);
    }
    .sub-item p{
        font-size: 14px;
     }
}

.about-dot{
    z-index: -1;
}
 /* about bottom */
 .about-bottom{
    grid-column: 2/3;
    margin-top: 169px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
 }
.contact-btn{
    width: 158px;
    height: 65px;
    background-color: transparent;
    border: none;
 }
.contact-btn:hover{
    cursor: pointer !important;
 }
 .contact-btn-wrapper{
    z-index: 2;
 }
 @media (max-width:600px){
    .about-bottom{
        margin-top: 75px;
     }
     .contact-btn-wrapper{
        width: 158.11px;
        height: 64.69px;
     }
 }
 .about-bottom-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

    @media (min-width:750px){
        .about-bottom{
            grid-column: 1/5;
            width: 100%;
            transform: translateY(-50px);
            padding-bottom: 50px;
        }
        .about-center{
            position: relative;
        }
        .about-center::after{
            content: "";
            height: 120px;
            background-color: var(--section-bg);
            position: absolute;
            top: calc(100%);
            width: 100%;
            z-index: 3;
        }
        .about-us-list-item {
            z-index: 4;
        }
        .about-bottom::after{
            content:"" ;
            border-radius: 50%;
            width: 1920px;
            height: 1920px;
            position: absolute;
            bottom: -50px;
            background-color: var(--section-bg);
            top: calc(100% - 430px);
            height: 400px;
            background-color: var(--section-bg);
            transform: translatey(100px);
            z-index: -1;
        } 
    }
    .about-center{
        width: 100%; 
    }
/* ------------------- */
/*       service        */
/* ------------------- */

 /* mobile first */
 #service{
    z-index: 5;
    position: relative;
    overflow-y: visible;
    margin-bottom: 100px;
}
.service-bg-img{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    clip-path: circle(58.1693% at 50% 50%);
}
@media (min-width:650px){
    .service-bg-img{
        object-fit: fill;
    }
}
.service-container{
   margin-top: 50px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 90% 1fr;
}
.service-container{
   padding-bottom: 50px;
}
@media (max-width:390px){
    .service-container{
        margin-top: -100px;
    }
}
@media (max-width:650px){
    .service-container{
        margin-top: 200px;
    }
}
.carousel{
    transform: translateY(-7px);
}
.service-top{
    grid-column: 2;
    justify-self: center;
    width: 100%;
}
.servive-carousel-container{
    grid-column: 2;
}
.service-contact-btn{
    grid-column: 2;
}

/* mobile service grid */

.title-wrapper{
    position: relative;
}
.title_eng{
    font-size: 5rem; /*80px*/
    font-family: a-otf-ud-shin-go-con80-pr6n, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #FFFFFF;
    white-space: nowrap; 
    z-index: 2;
}
.title_jp{
    z-index: 3;
    position: absolute;
    font-family: a-otf-ud-shin-go-con80-pr6n, sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0px;
    font-size: 22px;
}
.title-wrapper{
    display: grid;
    place-items: center;
    letter-spacing: 0px;
    width: fit-content;
}
.service-top{
    margin-bottom:38.57px ;
}
@media (min-width:1000px){
    .service-container{
        padding-top: 100px;
    }
}
/* service carosel  */
.carousel{
    grid-column: 2;
    height: 500px;
    position: relative;
}
.carousel .carousel-item{
    width: 308px;
    height: 408px;
    background-color: rgba(255, 255, 255, 1);
}
@media (min-width:750px){
    .carousel{
        grid-column: 2;
        height: 700px;
        position: relative;
    }
    .carousel .carousel-item{
        width: 410px;
        height: 545px;
    }
}
.carousel-item{
    z-index: 1;
}
.service-card_title{
    font-size: 27.04px;
    text-align: center;
    letter-spacing: 1.08px;
    color: #000000;
    margin-top: 23.51px;
    margin-bottom: 9.34px;
    font-family: a-otf-ud-shin-go-con80-pr6n, sans-serif;
    font-weight: 300;
    font-style: normal;
}
.service_card_description{
    text-align: center;
    width: 260px;
    height: 70px;
    padding-bottom: 47.57px;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 14px; /* 24px */
}
.service_card{
    width: 308px;
    height: 408px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 0.6);
}
#image,
#image2,
#image3,
#image4,
#image5,
#image6,
#image7{
    width: 218px;
    height: 169px;
    border-radius: 50%;
    margin-bottom: 30.34px;
}
.service-carosel-btn_img{
    object-fit: cover;
}
.carosel-btn{
    width: 80px;
    aspect-ratio: 1;
    border: none;
    background-color: transparent;
}
/*service carosel marker*/

.carousel .indicators .indicator-item {
    height: 10px !important;
    width: 10px !important;
}
.carousel .indicators .indicator-item:hover {
    border: 1px solid #000000 !important;
}
.carousel .indicators .indicator-item.active {
    height: 10px !important;
    width: 10px !important;
    background-color: #000000 !important;
}

@media (min-width:750px) {
    .carousel{
        overflow: visible;
        display: grid;
    }
    .carousel .indicators{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #image,
    #image2,
    #image3,
    #image4,
    #image5,
    #image6,
    #image7{
        width: 290.8px;
        height: 225.66px;
        border-radius: 50%;
        margin-bottom: 40.78px;
    }
}
.carosel-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.prev{
    position: absolute;
    top: 50%;
    left:10px;
    transform: translate(-50%,-50%);
}
.carosel{
    position: relative;
}
.next{
    position: absolute;
    top: 50%;
    right:0px;
    transform: translate(-50%,-50%);
}
.prev,
.next{
    width: 50px;
    aspect-ratio: 1;
    opacity: 0.6;
    z-index: 2;
}
.prev:hover,
.next:hover{
    opacity: 1;
    cursor: pointer;
}
.service-carosel-btn_img{
    width: 50px !important;
    height: 50px !important;
}
@media (min-width:800px){
.prev,
.next{
    width: 80px;
    height: 80px;
}
.prev{
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%,-50%);
    width: 80px !important;
    height: 80px !important;
}
.next{
    position: absolute;
    top: 50%;
    right: 31.5%;
    transform: translate(-50%,-50%);
    width: 80px !important;
    height: 80px !important;
}
.service-carosel-btn_img{
    width: 80px !important;
    height: 80px !important;
}
}
/* service contact button */
.service-contact-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 33.5px;
    width: 100%;
}
.service-title{
    font-size: 8vmin;
    font-weight: bold;
    margin:0;
    white-space:nowrap;
    position:absolute;
    left:100%;
    top:50%;
    transform:translateY(-50%);
  }
  .carosel-btn{
    width: 100px;
    height: 100px;
  }
/* desktop version  */

@media (min-width:1200px){
    .service_slides{
       justify-content: space-around;
       align-items: center;
       position: relative;
    }
    .service_card_description{
        font-size: 14px;
    }
}
@media (min-width:750px){
    .servive-carousel-container{
        width: 100%;
    }
    .service_card{
        width: 410px;
        height: 545px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 40px;
        background-color: rgba(255, 255, 255, 0.6);
    }
    .service-card_title{
        font-size: 36px;
        margin-top: 32px;
        margin-bottom: 11px;
        font-family: a-otf-ud-shin-go-con80-pr6n, sans-serif;
        font-weight: 300;
        font-style: normal;
    }
    .service_card_description{
        text-align: center;
        width: 334px;
        height: 93px;
        padding-bottom: 63.46px;
        font-family: 'Noto Sans JP', sans-serif;
        font-size: 18px;
    }
}
@media(min-width:750px){
    .carousel{
        transform: translateY(-200px);
    }
}
#service::after{
    content:"" ;
    border-radius: 50%;
    width: 100%;
    position: absolute;
    bottom: -50px;
    height: 100px;
    background-color: var(--section-bg);
    }
/* ------------------- */
/*       topics         */
/* ------------------- */

#topics{
    margin-top: 10px; 
    color: black;
    gap: 37.16px;
    padding-bottom: 0px;
    padding-top: 65px;
    position: relative;
    transform: translateY(-300px);
}
@media(min-width:750px){
    #topics{
        transform: translateY(50px);
    }
}
@media (max-width:750px){
    .topics-main{
        background-color: var(--section-bg);
    }
    .topics-main::after{
        content:"" ;
        border-radius: 50%;
        width: 100%;
        position: absolute;
        bottom: -50px;
        height: 100px;
        background-color: var(--section-bg);
        z-index: -1;
    }
}
        .topics-title{
            font-size: 8vmin;
            font-weight: bold;
            margin:0;
            white-space:nowrap;
            position:absolute;
            left:100%;
            top:50%;
            transform:translateY(-50%);
          }
  /* gird layout */
  .topics-main{
    font-family: 'Noto Sans JP', sans-serif;
    letter-spacing: 0.56px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: translateY(-150px);
    padding-bottom: 90px;
    margin-top: -150px;
  }
  .topic{
    --beforeAnimation: 0;
    width: 350px;
    height: 118.82px;
    padding: 25px;
    position: relative;
  }
  .blog{
    height: 50.47px;
    overflow: hidden;
    font-size: 14px;
  }
  .blog_date{
    color: #C8C8C8;
    font-size: 14px;
  }
  .topic_new{
    background-color: white;
  }
  .topic::before{
    content: "";
    width: var(--beforeAnimation);
    height: 3px;
    background-color:  #E6EBF5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
}
.topic_last::after{
    content: "";
    width: var(--beforeAnimation);
    height: 3px;
    background-color: #E6EBF5;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
}
.topics-dot{
    z-index: -2;
    width:110vmax;
    height:110vmax;
}
@media (min-width:750px){
    .topics-dot{
        z-index: -2;
        width:142vmax;
        height:70vmax;
    }
}
@media (min-width:750px){
    .topics-dot{
        z-index: -2;
        width:142vmax;
        height:130vmax;
    }
}
@media (min-width:1100px){
    .topics-dot{
        z-index: -2;
        width:142vmax;
        height:70vmax;
    }
}
@media (min-width:1120px){
    .topics-dot{
        z-index: -2;
        width:142vmax;
        height:120vmax;
    }
}
@media (min-width:1200px){
    .topics-dot{
        z-index: -2;
        width:142vmax;
        height:65vmax;
    }
}
/* desktop */

@media (min-width:800px){
    .topic{
        --beforeAnimation: 0;
        width: 900px;
        height: 94.86px;
        padding-top: 20.52px;
        padding-top: 26.35px;
        padding-left: 20px;
        position: relative;
      }
      .blog{
        height: 30px;
        width: 652px;
        font-size: 18px;
        display: grid;
        place-items: center;
        text-align: end;
        justify-items: start;
      }
      .blog_date{
        color: #C8C8C8;
        font-size: 14px;
        margin-top: 1px;
      }
}
/* ------------------- */
/*       company       */
/* ------------------- */
  .company-title{
    font-size: 8vmin;
    font-weight: bold;
    margin:0;
    white-space:nowrap;
    position:absolute;
    left:100%;
    top:50%;
    transform:translateY(-50%);
  }
  h1, h2 {
      font-family:Prompt, sans-serif;
  }
  .company-main,
  .main-common {
    padding:5vw;
    color:black;
    padding-bottom: 0;
  }
.info_number[x-apple-data-detectors]{
    color: inherit !important;
    text-decoration: none !important;
}
  .title-wrapper{
      position: relative;
  }
  .title_eng{
      font-size: 80px; /*80px*/
      font-family: a-otf-ud-shin-go-con80-pr6n, sans-serif;
      font-weight: 300;
      font-style: normal;
      color: #FFFFFF;
      white-space: nowrap; 
      z-index: 2;
  }
  .title_jp{
      z-index: 3;
      position: absolute;
      font-family: a-otf-ud-shin-go-con80-pr6n, sans-serif;
      font-weight: 300;
      font-style: normal;
      letter-spacing: 0px;
      font-size: 22px;
    color:black;
  }
  .title-wrapper{
      display: grid;
      place-items: center;
      letter-spacing: 0px;
      width: fit-content;
  }
  .company-info{
      display: grid;
      place-content: center;
  }

  @media (min-width:750px) {
    .company-info{
        transform: translateY(-100px);
    }
  }
  .info-list{
      width: 100%;
      z-index: 10; 
  }
  .info-list-item{
      display: grid;
      grid-template-columns: 50px  201px 1fr;
      gap:50px;
      width: 350px;
      height: 90px;
  }
  .info-title{
      font-size: 16px;
      text-align: left;
      font-family: 'Noto Sans JP', sans-serif;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 49px;
  }
  .info-description{
    font-size: 16px;
      text-align: left;
      font-family: 'Noto Sans JP', sans-serif;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
  }
  @media (min-width:800px){

    .info-list-item{
        display: grid;
        gap:50px;
        width: 500px;
        height: 90px;
    }
      .info-title{
          font-size: 18px;
          width: 55px; 
      }
      .info-description{
          font-size: 18px;
      }
      .title_jp{
          font-size: 30px;
      }
      .title_eng{
          font-size: 130px;
      }
  }
  .company_tel p{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
  }
  .fax{
      padding-left: 10px;
     
  }
  
  /* styles for the lines */
  
  .info-list-item{
      position: relative;
  }
  .info-title::before{
      content: "";
      width: 55px;
      height: 3px;
      background-color: #FFFFFF;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
  }
  .info-title::after{
      content: "";
      width: 100%;
      height: 1px;
      background-color:  rgb(230, 235, 345); ;
      position: absolute;
      top: 1px;
      left: 0;
  }
  @media (min-width:750px){
      .info-list-item{
          display: flex;
          gap:138px;
          width: 900px;
          height: 95px;
      }
      .info-title{
          width: 55px;
      }
      .info-description{
         flex: 1;
      }
  }
  .temp{
    color:black;
  }
#company{
    position: relative;
    transform: translateY(300px);
    margin-top: -100px;
}
@media (max-width:750px){
    #company{
        position: relative;
        transform: translateY(-350px);
        margin-top: -235px;
    }
}
.company-title {
    z-index: -1;
}
.company-dot{
    z-index: -2;
    width:120vmax;
    height:120vmax;
}

@media (min-width:764px){
    .company-dot{
        z-index: -2;
        width:142vmax;
        height:70vmax;
    }
}

/* ------------------- */
/*       contact       */
/* ------------------- */
#contact{
    color: black;
     z-index: 10;
     position: relative;
     margin-top: -50px;
}
@media (max-width:750px){
    #contact{
        color: black;
         z-index: 10;
         position: relative;
         margin-top: -550px;
    }
}

/* contact grid */
.contact-wrapper{
    padding:5vw;
    gap: 32.89px;
    /* new */
   display: grid;
   place-content: center;
}
.contact-header_sub{
    line-height: 28px;
}
@media (min-width:800px){
    .contact-wrapper{
        gap: 114px;
    }
    .contact-header{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }
    .contact-top_left{
        width: 350px;
    }
    .contact-header_sub{
        width:390px ;
        line-height: 35px;
    }
}
/* custom radio btn */
.radio-label{
    display: flex;
    align-items: center;
}
.inquiry-details{
    display: flex;
    flex-direction: column;
    gap: 18.84px;
}
.custom-radio{
    display: inline-block;
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 8px;
    border: 2px solid var(--section-bg);
    background-color: var(--section-bg);
}

.radio-label:hover .custom-radio,
[type="radio"]:focus + label .custom-radio{
    transform:scale(1.1);
}
[type="radio"]:checked + label .custom-radio{
    background-color: black;
    border: 3px solid var(--section-bg);
}

/*contact typography */

.contact-header_jp{
    font-size: 20px;
    font-family: a-otf-ud-shin-go-con80-pr6n, sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0px;
    margin-bottom: 4.6px;
}
.contact-header_title{
    font-size: 60px;
    font-family: a-otf-ud-shin-go-con80-pr6n, sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 2.4px;
    margin-top: 0;
}
.contact-form{
    font-size: 16px;
    font-family: 'Noto Sans JP', sans-serif;
    letter-spacing: 0.64px;
}

.contact-header_sub{
    font-size: 16px;
    font-family: a-otf-ud-shin-go-con80-pr6n, sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0px;
    line-height: 28px;
}
.contact-header_sub small{
    font-size: 14px;
}
.radio-label{
    font-size: 16px;
    
}

.terms-title{
    font-size: 16px;
}
#contact label{
    color:black
}

#agree_label{
    width: 100%;
    font-size: 16px;
}
.terms{
    font-size: 14px;
    background-color: var(--section-bg);
}

.label-name{
    font-size: 16px;
}

@media (min-width:800px){
    .contact-header_jp{
        font-size: 30px;
        margin-bottom: 6.21px;
    }
    .contact-header_title{
        font-size:72.05px;
        letter-spacing: 2.88px;
    }
    .contact-form{
        font-size: 18px;
        letter-spacing: 0.72px;
    }
    
    .contact-header_sub{
        font-size: 22px;
        line-height: 35px;
    }
    .contact-header_sub small{
        font-size: 16px;
    }
    .radio-label{
        font-size: 18px;
        
    }
    
    .terms-title{
        font-size: 18px;
    }
    
    #agree_label{
        font-size: 18px;
    }
    .terms{
        font-size: 18px;
        background-color: var(--section-bg);
    }
    .label-name{
        font-size: 18px;
    }
}

/* contact form other styles */
#contact input{
    border: none;
    height: 60px;
    background-color: var(--section-bg);
    padding-left: 32px;
    width: 90%;
    border-radius: 10px;
}
#contact [type="checkbox"]{
display: none;
pointer-events: none;
aspect-ratio: 1;
background-color: var(--section-bg);
}
#agree_label{
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:  8.84px;
}
#agree_label p{
    font-size: 16px;
    letter-spacing: 0.64px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
#agree_label svg{
    width: 20px;
    height: 20px;
}
.checked{
    display: none;
}
.label-name_wrapper{
    display: flex;
    align-items: center; 
}
.star{
    font-size: 10px;
    align-self: flex-start;
  }
.terms{
    overflow: scroll;
    padding: 0;
    width: 100%;
    height: 246px;
   
}
.terms-padding{
overflow: hidden;
padding:20px;
height: 290px;
background-color: var(--section-bg);
margin-bottom: 26.93px;
border-radius: 10px;
}
@media (min-width:800px){
    #contact input{
        height: 60px;
        width: 660px;
    }
    #agree_label p{
        font-size: 18px;
        letter-spacing: 0.72px;
    }
    .contact-item{
        display: flex;
        justify-content: space-between;
    }
    .contact-form{

        width: 856.65px;
    }
    
    .label-name_wrapper{
        display: flex;
        align-items: center;
    }
    .terms{
        overflow-y: scroll !important;
        padding: 0;
        height: 246px;
    }
    .terms-padding{
    overflow: hidden;
    padding-left:30px;
    padding-top:38.19px;
    padding-right: 30px ;
    padding-bottom: 38.19px;
    height: 290px;
    width: 660px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 67px;
    }
    .terms-wrapper{
        display: grid;
        grid-template-columns: 1fr 660px 1fr;
    }
    .terms-title{
       grid-column: 2;
       display: flex;
    }
    .terms-padding{
        grid-column: 2;
    }
}
/* contact spacing and margins */
.contact-wrapper{
    margin-bottom: 32.89px;
}
.contact-header_jp{
margin-bottom: 4.6px;
}

.contact-header_title{
    margin-bottom: 23.65px;
}
.name-wrapper{
    margin-bottom: 27.13px;
}

.name_kana-wrapper{
    margin-bottom: 26.08px;
}
.your_company-wrapper{
    margin-bottom: 27.24px;
}
.your_company-wrapper{
    margin-bottom: 27.24px;
}

.email-wrapper{
    margin-bottom: 47.14px;
}
.label-name{
    margin-bottom: 12px;
}
.inquiry-details{
    margin-bottom: 41.5px;
}
.terms-and-conditions_agree-wrapper{
    display: flex;
    align-items: center;
    gap: 8.84px;
    margin-bottom:58.06px;
}
.terms-title{
    margin-bottom: 11.8px;
    text-align: center;
}
.radio-wrapper{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.your_inquiry-wrapper{
    margin-bottom: 47.97px;
}
@media (min-width:800px){
    .contact-wrapper{
        margin-bottom: 0px;
    }
    .contact-header_jp{
    margin-bottom: 6.21px;
    }
    .contact-header_title{
        margin-bottom: 50px;
    }
    .name-wrapper{
        margin-bottom: 39px;
    }
    .name_kana-wrapper{
        margin-bottom: 39px;
    }
    .your_company-wrapper{
        margin-bottom: 39px;
    }
    .email-wrapper{
        margin-bottom: 55px;
    }
    .label-name{
        margin-bottom: 0px;
        display: grid;
        place-content: center;
    }
    .inquiry-details{
        margin-bottom: 37.73px;
        flex-direction: row;
    }
    .star{
        display: none;
    }
    .required_item{
        position: relative;
    }
    .required_item::after{
        content: "※";
        position: absolute;
        top:-10px;
        right: -5px;
        height: 15px;
        width: 10px;
        font-size: 10px;
    }
    .terms-and-conditions_agree-wrapper{
        display: grid;
       place-content: center;
        gap: 8.84px;
        margin-bottom:66px;
    }
    .terms-title{
        margin-bottom: 11.8px;
        text-align: center;
        align-self: flex-start;
    }
    .radio-wrapper{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .your_inquiry-wrapper{
        margin-bottom: 47.62px;
    } 
}

/* submit btn */

.submit-btn{
    width: 200px;
    height: 60px;
    background-color: transparent;
    border: none;
 }
.submit-btn:hover{
    cursor: pointer !important;
 }
 .submit-btn-wrapper{
    z-index: 2;
   display: flex;
   justify-content: center;
   align-items: center;
 }
#contact{
    padding-bottom: 50px;
}
 #back-to-top-btn img{
    width: 80px;
    aspect-ratio: 1;
    object-fit: cover;
    position: absolute;
    bottom: -10px;
    right: 20px;
 }
 #back-to-top-btn:hover{
    cursor: pointer;
 }
/* ------------------- */
/*       footer        */
/* ------------------- */

#footer{
    width: 100%;
    height: 100px;
    background-color: #000000;
    display: grid;
    place-items: center;
}
.copyright{
    color: #FFFFFF;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 12px;
    letter-spacing: 0.48px;
    text-align: center;
}
@media (min-width:769px){
    .copyright{
        font-size: 18px;
        letter-spacing: 0.72px;
    }  
}
@media (min-width:1050px){
    .title_jp{
        font-size: 30px;
    }
    .title_eng{
        font-size: 130px;
    }
}  
 @media (max-width:600px){
    .animate-1,
    .animate-2,
    .animate-3,
    .animate-4,
    .animate-5,
    .animate-6,
    .animate-7,
    .animate-8{
        font-size: 14px; 
    }
    
     }
  /* card adjustments */
@media (min-width:750px){
    #card,
    #card2,
    #card3,
    #card4,
    #card5,
    #card6,
    #card7{
        display: grid;
        grid-template-columns: 1fr 300px 1fr;
    }

    #image,
    #image2,
    #image3,
    #image4,
    #image5,
    #image6,
    #image7{
        grid-column: 2;
        margin-bottom: 0;
    }
    .service-card_title{
        grid-column: 2;
    }
    .service_card_description{
        grid-column: 2;
        margin-bottom: 60px;
        }
    }
   @media (min-width: 980px) {
    .carousel,
    .loop-three{
            background: transparent;
            transform: translateY(10px);
     }
}
@media (min-width: 768px) and (max-width: 980px){
    .carousel,
    .loop-three{
            background: transparent;
            transform: translateY(-10px);
     }
}
@media (min-width:740px){
    .carousel,
    .loop-three{
            background: transparent;
            margin-top: -300px;
     }
}
@media (max-width:739px){
    .carousel,
    .loop-three{
            background: transparent;
            margin-top: -250px;
     }
}
/* try to fix the topics  */
    @media (min-width: 980px) {
        #topics{
            position: relative;
        }
        #topics::after{
            content: "";
            border-radius: 100%;
            width: 140%;
            left: -20%;
            position: absolute;
            bottom: -150px;
            height: 1200px;
            z-index: -2;
           
            background-color: var(--section-bg);
        }
        .topics-dot{
            z-index: -3;
            width:140vmax;
            height:67vmax;
        }
    }
    @media (min-width: 980px) {
        #company{
            position: relative;
        }
        #company::after{
            content: "";
            border-radius: 100%;
            width: 140%;
            left: -20%;
            position: absolute;
            bottom: 150px;
            height: 400px;
            z-index: -2;
            background-color: var(--section-bg);
        }
        .company-dot{
            z-index: -3;
            width:130vmax;
            height:85vmax;
        }
        .company-main{
            position: relative;
            transform: translateY(-200px);
         }
        .company-main{
            transform: translateY(-300px);
        }
        #contact{
            color: black;
             z-index: 10;
             position: relative;
             margin-top: 100px;
             z-index: 10;
             background-color: white;
        }
        .custom-shape-divider-bottom-1700216422 {
            position: absolute;
            bottom: -200px;
            left: 0px;
            width: 100%;
            overflow: hidden;
            line-height: 0;
            z-index: -1;
        }
        
        .custom-shape-divider-bottom-1700216422 svg {
            position: relative;
            display: block;
            width: calc(140% + 1.3px);
            height: 181px;
        }
        
        .custom-shape-divider-bottom-1700216422 .shape-fill {
            fill: white;
        }
        #company{
           position: relative;
        }
    }
   @media (max-width:980px) {
    .custom-shape-divider-bottom-1700216422 {
        position: absolute;
        bottom: -200px;
        left: 0px;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        z-index: -1;
        display: none;
    }
    .custom-shape-divider-bottom-1700216422 svg {
        position: relative;
        display: block;
        width: calc(140% + 1.3px);
        height: 181px;
        display: none;
    }
    .custom-shape-divider-bottom-1700216422 .shape-fill {
        fill: white;
        display: none;
    }
   }
   /* service */
   @media (min-width:980px){
    #service::after{
        content:"" ;
        border-radius: 50%;
        width: 200%;
        position: absolute;
        bottom: -200px;
        left: -50%;
        height: 3000px;
        background-color: var(--section-bg);
        z-index: -5;
        clip-path: circle(50% at 50% 94%);
        } 
}
 /* z index fixes */
 #about::after{
    content:"" ;
    border-radius: 50%;
    width: 200%;
    position: absolute;
    bottom: 0px;
    left: -50%;
    height: 500px;
    background-color: var(--section-bg);
    z-index: -5;
    clip-path: circle(50% at 50% 94%);
    } 

    @media (max-width:350px){
        #about::after{
            content:"" ;
            border-radius: 50%;
            width: 200%;
            position: absolute;
            bottom: 30px;
            left: -50%;
            height: 1000px;
            background-color: var(--section-bg);
            z-index: -5;
            clip-path: circle(50% at 50% 94%);
            } 
    }
    @media (min-width: 400px) and (max-width: 500px){
        #about::after{
            content:"" ;
            border-radius: 50%;
            width: 200%;
            position: absolute;
            bottom: 70px;
            left: -50%;
            height: 1000px;
            background-color: var(--section-bg);
            z-index: -5;
            clip-path: circle(50% at 50% 94%);
            } 
    }


    @media (min-width:800px){
        #about::after{
            content:"" ;
            border-radius: 100%;
            width: 200%;
            position: absolute;
            bottom: -100px;
            left: -50%;
            height: 3000px;
            background-color: var(--section-bg);
            z-index: -5;
            clip-path: circle(50% at 50% 94%);
            } 
    }
    .loop-two-desktop{
        position: absolute;
        bottom:-250px;
        right: 153%;
        transform: scale(4);
    }

    @media(min-width:800px){
        .loop-two-desktop{
            position: absolute;
            bottom:-490px;
            right: 65%;
            transform: scale(3);
        }
    }
    .loop-two-desktop{
        display: none;
    }


    .loop-two-desktop{
        display: block;
    }
    .loop-two{
        display: none;
    }
    .about-center{
        z-index: 10;
     }
    .about-us-list{
        z-index: 11;
    }
     .about-bottom{
        z-index: 11;
     }
     .about-bottom::after{
        background-color: transparent;
      z-index: 11
     }
    
     .contact-btn{
        z-index: 12;
     }
    
     #serviceTrigger{
        z-index: 12;
     }